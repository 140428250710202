.ui-video-seek-slider {
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
}
.ui-video-seek-slider:focus {
  outline: none;
}
.ui-video-seek-slider .track {
  padding: 0;
  cursor: pointer;
  outline: none;
}
.ui-video-seek-slider .track:focus {
  border: 0;
  outline: none;
}
.ui-video-seek-slider .track .main {
  width: 100%;
  outline: none;
  height: 18px;
  top: 0;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ui-video-seek-slider .track .main:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  -webkit-transition: height 0.1s;
  transition: height 0.1s;
  outline: none;
}
.ui-video-seek-slider .track .main .inner-seek-block {
  position: absolute;
  width: 100%;
  height: 3px;
  -webkit-transition:
    height 0.1s,
    opacity 0.4s;
  transition:
    height 0.1s,
    opacity 0.4s;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.ui-video-seek-slider .track .main:focus {
  border: 0;
  outline: none;
}
.ui-video-seek-slider .track .main .buffered {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 2;
}
.ui-video-seek-slider .track .main .seek-hover {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
.ui-video-seek-slider .track .main .connect {
  background-color: white;
  z-index: 3;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.ui-video-seek-slider .track .main.with-gap .inner-seek-block,
.ui-video-seek-slider .track .main.with-gap:before {
  width: calc(100% - 2px);
  margin: 0 auto;
}
@media (hover) {
  .ui-video-seek-slider .track .main:hover:before {
    height: 8px;
  }
  .ui-video-seek-slider .track .main:hover .inner-seek-block {
    height: 8px;
  }
}
.ui-video-seek-slider .thumb {
  pointer-events: none;
  position: absolute;
  width: 0;
  left: -6px;
  z-index: 4;
  top: 3px;
}
.ui-video-seek-slider .thumb .handler {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-transition:
    opacity 0.2s,
    -webkit-transform 0.2s;
  transition:
    opacity 0.2s,
    -webkit-transform 0.2s;
  transition:
    transform 0.2s,
    opacity 0.2s;
  transition:
    transform 0.2s,
    opacity 0.2s,
    -webkit-transform 0.2s;
}
.ui-video-seek-slider .thumb.active .handler {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.ui-video-seek-slider .hover-time {
  text-shadow: 1px 1px 1px #000;
  position: absolute;
  line-height: 18px;
  font-size: 14px;
  color: #ddd;
  bottom: 5px;
  left: 0;
  padding: 5px 10px;
  opacity: 0;
  pointer-events: none;
  text-align: center;
}
.ui-video-seek-slider .hover-time.active {
  opacity: 1;
}
.ui-video-seek-slider .hover-time .preview-screen {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 160px;
  height: 90px;
  border-radius: 5px;
  background-color: #000;
  margin: 0 auto 10px;
}
.ui-video-seek-slider:hover .track .main .seek-hover {
  opacity: 1;
}
